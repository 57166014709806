<template>
    <div class="Fundingsupport">
        <div class="Addfunds">
            <el-button size="mini" type="primary" @click="dialogVisible=true">添加经费</el-button>
        </div>
        <el-table 
            :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)" 
            style="width: 100%"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="index" width="100" label="#"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="money" label="金额"></el-table-column>
            <el-table-column prop="bill" label="票据">
                <template slot-scope="scope">
                    <img :src="scope.row.bill" alt="" width="100" height="80">
                </template>
            </el-table-column>
            <el-table-column prop="money" label="状态">
                <template slot-scope="scope">
                    <span>{{scope.row.state==0?'未审核':scope.row.state==1?'指导教师通过':scope.row.state==2?'指导教师驳回':scope.row.state==3?'院系通过':scope.row.state==4?'院系驳回':scope.row.state==5?'待报销':scope.row.state==5?'已报销':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="cause" label="驳回原因">
                <template slot-scope="scope">
                    <span>{{scope.row.cause==null || scope.row.cause==''?'无':scope.row.cause}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="level" label="操作" width="150px">
                <template slot-scope="scope">
                    <el-button @click="deleted(scope.row)" type="text">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-sizes="[10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.length">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <!-- 弹窗 -->
        <div class="Fundspopup">
            <el-dialog
                title="添加经费"
                :visible.sync="dialogVisible"
                width="580px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px">
                    <el-form-item label="名 称" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="金 额" prop="money">
                        <el-input v-model="ruleForm.money">
                            <template slot="append">元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="票 据" prop="bill">
                        <el-upload
                            class="avatar-uploader"
                            :action="this.$store.state.uploadimage"
                            :headers="headers"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess">
                            <img v-if="ruleForm.bill" :src="ruleForm.bill" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" size="small" @click="addqued('ruleForm')">确 定</el-button>
                    <el-button size="small" @click="closei()">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    computed: {
        headers() {
            return{
                "Authorization": this.$store.state.token // 直接从本地获取token就行
            }
        }
    },
    data () {
        return {
            tableData:[],
            pageSize:10,
            totalnum:0,
            currentPage:1,
            dialogVisible:false,
            ruleForm:{
                bill:'',
                name:'',
                money:'',
            },
            rules:{
                name:[
                    { required: true, message: '请输入名称', trigger: 'blur'}
                ],
                money:[
                    { required: true, message: '请输入金额', trigger: 'blur'}
                ],
                bill:[
                    { required: true, message: '请上传票据', trigger: 'change'}
                ],
            }
        }
    },
    methods: {
        getapp(){
            this.axios.expenditureList({
                params:{
                    itemId:this.$store.state.itemId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.rows
                }else{
                    this.tableData = [ ]
                }
            }).catch(err=>{

            })
        },
        //添加经费确定
        addqued(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.itemId = this.$store.state.itemId
                    this.ruleForm.state = 0
                    this.axios.addDcItemExpenditure(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==200){
                            this.dialogVisible = false
                            this.getapp()
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
            
        },
        deleted(rows){
            this.axios.expenditureDelete({
                params:{
                    expId:rows.expId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.getapp()
                }
            }).catch(err=>{

            })
        },
        handleAvatarSuccess(res, file) {
            this.ruleForm.bill = res.url
        },
        closei(){
            this.dialogVisible = false
        },
        handleClose(done){
            console.log(done)
        },
        handleSizeChange(val){
            this.pageSize = val;
        },
        handleCurrentChange(val){
            this.currentPage = val;
        },
    },
    mounted () {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../assets/css/student/Fundingsupport.css';
</style>
<style>
.Fundingsupport .el-table__body-wrapper{
    min-height: calc(100vh - 340px);
}
.Fundingsupport .el-table__empty-block{
    min-height: calc(100vh - 340px);
}
.Fundspopup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.Fundspopup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.Fundspopup .el-dialog__footer{
    text-align: center;
}
.Fundspopup .el-form-item__content{
    width: 350px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>